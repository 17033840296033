import { Response } from '../../../core/types/http';
import uploadApi, { UploadDetails } from '../api/upload';
import { BulkActionService } from './bulkActionService';

export interface UploadCallbacks {
  /**
   * Handles the start of the upload process
   */
  onUploadStarted: () => void;
  /**
   * Handles the success of a file upload
   */
  onFileUploadSuccess: () => void;
  /**
   * Handles the error of a file upload
   */
  onFileUploadError: (fileName: string) => void;
  /**
   * Handles the start of processing of the uploaded files
   */
  onProcessingStarted: () => void;
  /**
   * Handles the success of the upload & processing
   */
  onUploadSuccess: (uploadDetails: UploadDetails) => void;
  /**
   * Handles total failure of upload/processing
   */
  onUploadError: () => void;
}

/**
 * Service to upload files
 */
export class UploadService {
  /**
   * Uploads the files to the given folder.
   * Will automatically create the notification and update its progress
   *
   * @param files - the files to upload
   * @param values - values for the upload task
   * @param taskName - task to process the upload
   * @param callbackManager - object of callback functions
   * @return nothing on success, or an error
   */
  static async uploadFiles(
    files: File[],
    values: { [x: string]: any },
    taskName: string,
    callbackManager: UploadCallbacks
  ): Promise<void> {
    try {
      if (!files.length) {
        throw new Error('Did not receive any files to upload');
      }

      const createUploadRes = await uploadApi.createUpload();
      const uploadId = createUploadRes.body;

      callbackManager.onUploadStarted();

      // Upload files 1 by 1 to avoid monopolizing xhr threads
      for (const file of files) {
        try {
          await uploadApi.sendFileToUpload(uploadId, file);
          callbackManager.onFileUploadSuccess();
        } catch (err) {
          callbackManager.onFileUploadError(file.name);
        }
      }

      // Start processing
      callbackManager.onProcessingStarted();
      await uploadApi.executeUpload(uploadId, values, taskName);

      // Start polling asynchronously
      void BulkActionService.pollBulkAction<UploadDetails>(
        uploadId,
        {
          onStatusFinished: callbackManager.onUploadSuccess.bind(callbackManager),
          onStatusInError: callbackManager.onUploadError.bind(callbackManager),
        },
        { getBulkActionDetails: UploadService.getUploadDetails }
      );
    } catch (err) {
      callbackManager.onUploadError();
    }
  }

  /**
   * @param uploadId - the id of the upload to retrieve the details of
   * @returns the api response or error
   */
  static async getUploadDetails(uploadId: string): Response<UploadDetails> {
    return uploadApi.getUploadById(uploadId);
  }
}

import Vue from 'vue';

import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { UploadDetails } from '../api/upload';
import { UploadStates } from '../constants/upload';
import { IAlertsService, INotificationsService } from './../../../platform/services/types';
import { UploadService } from './uploadService';

const eventBus = new Vue();

/**
 * Manages the upload and its notification state
 */
export class UploadManager {
  /**
   * The files to upload
   */
  files: File[];
  /**
   * Values for the upload task
   */
  values: { [x: string]: any };
  /**
   * Options to customise the handling of the upload
   */
  opts: {
    notificationComponent: Vue;
    extras: object;
  };

  /**
   * The ID of the notification associated to this upload
   */
  notificationId: number;
  /**
   * The count of files that were uploaded successfully
   */
  successFileCount: number;
  /**
   * The names of the files that could not be uploaded
   */
  errorFileNames: string[];

  /**
   *
   * @param files - the files to upload
   * @param values - values for the upload task
   * @param extras - extra data for the upload
   */
  constructor(
    files: File[],
    values: { [x: string]: any },
    opts: {
      notificationComponent: Vue;
      extras: object;
    }
  ) {
    this.files = files;
    this.values = values;
    this.opts = opts;
    this.notificationId = -1;
    this.successFileCount = 0;
    this.errorFileNames = [];
  }

  // STATIC METHODS

  /**
   * Uploads the files to the given folder.
   * Will automatically create the notification and update its progress
   *
   * @param files - the files to upload
   * @param values - values for the upload task
   * @param taskName - task to process the upload
   * @param opts - options to customise the handling of the upload
   * @param opts.notificationComponent - the notification component to create
   * @param opts.extras - extra **local** data that will be passed to the notification
   */
  static async uploadFiles(
    files: File[],
    values: { [x: string]: any },
    taskName: string,
    opts: {
      notificationComponent: Vue;
      extras: object;
    }
  ): Promise<void> {
    if (!files.length) {
      return Promise.resolve();
    }

    let uploadManager;

    try {
      uploadManager = new UploadManager(files, values, opts);
      await uploadManager.init();

      void UploadService.uploadFiles(files, values, taskName, uploadManager);

      services.getService<INotificationsService>('notifications')?.openNotifications();
    } catch (err) {
      if (uploadManager) {
        uploadManager.onUploadError();
      }
    }
  }

  /**
   * Emits an event
   *
   * @param {string} eventName - the event to emit
   * @param {any[]} args - arguments to pass to listeners
   */
  static $emit(eventName: string, ...args: unknown[]) {
    eventBus.$emit(eventName, ...args);
  }

  /**
   * Attaches a listener
   *
   * @param {string | string[]} event - the event to listen to
   * @param {Function} callback - the callback to invoke when the event is received
   */
  static $on(event: string | string[], callback: (...args: unknown[]) => void) {
    eventBus.$on(event, callback);
  }

  /**
   * Removes a listener
   *
   * @param {string | string[]} [event] - the event to remove all callbacks,
   * or the given callback for
   * @param {Function} [callback] - callback to remove
   */
  static $off(event: string | string[], callback: (...args: unknown[]) => void) {
    eventBus.$off(event, callback);
  }

  // INSTANCE METHODS

  /**
   * Creates the initial notification for an upload
   */
  async init(): Promise<void> {
    this.notificationId =
      (await services
        .getService<INotificationsService>('notifications')
        ?.createNotification(this.opts.notificationComponent, {
          state: UploadStates.CREATING,
          values: this.values,
          totalFileCount: this.files.length,
          successFileCount: 0,
          errorFileNames: [],
          uploadedFileIds: [],
          extras: this.opts.extras,
        })) ?? -1;
  }

  /**
   * Updates the notification for the beginning of file uploads
   */
  onUploadStarted(): void {
    services
      .getService<INotificationsService>('notifications')
      ?.updateNotification(this.notificationId, {
        state: UploadStates.UPLOADING_FILES,
      });
  }

  /**
   * Updates the notification when a file upload has succeeded
   */
  onFileUploadSuccess(): void {
    this.successFileCount++;
    services
      .getService<INotificationsService>('notifications')
      ?.updateNotification(this.notificationId, {
        successFileCount: this.successFileCount,
      });
  }

  /**
   * Updates the notification when a file upload failed
   *
   * @param {string} fileName - the total number of files that succeeded in uploading
   */
  onFileUploadError(fileName: string): void {
    this.errorFileNames.push(fileName);
    services
      .getService<INotificationsService>('notifications')
      ?.updateNotification(this.notificationId, {
        errorFileNames: this.errorFileNames,
      });
  }

  /**
   * Updates the notification for the beginning of processing
   */
  onProcessingStarted(): void {
    services
      .getService<INotificationsService>('notifications')
      ?.updateNotification(this.notificationId, {
        state: UploadStates.PROCESSING,
      });
  }

  /**
   * Updates the notification for a complete upload and processing success
   *
   * @param uploadDetails - the details of the upload
   */
  onUploadSuccess(uploadDetails: UploadDetails): void {
    const { uploadedFileIds } = uploadDetails;

    services
      .getService<INotificationsService>('notifications')
      ?.updateNotification(this.notificationId, {
        state: UploadStates.DONE_SUCCESS,
        uploadedFileIds,
      });

    services
      .getService<IAlertsService>('alerts')
      ?.alertSuccess(i18n.t('common.upload.alert.success') as string);
  }

  /**
   * Updates the notification for a total upload failure
   */
  onUploadError(): void {
    if (this.notificationId !== -1) {
      services
        .getService<INotificationsService>('notifications')
        ?.updateNotification(this.notificationId, {
          state: UploadStates.DONE_ERROR,
        });
    }

    services
      .getService<IAlertsService>('alerts')
      ?.alertError(i18n.t('common.upload.alert.error') as string);
  }
}

<template>
  <div>
    <template v-if="loadingModuleSettings">
      <pui-common-spinner :message="$t('platform.app.loading')" fullscreen></pui-common-spinner>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import services from '../../../core/services';

export default {
  name: 'PuiHotModuleLayout',
  props: {
    /**
     * Name to use to check if the module settings are loading
     */
    appAlias: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * @returns {boolean} if the module settings are loading
     */
    loadingModuleSettings() {
      return services.getService('applications').getIsLoadingApp(this.appAlias);
    },
  },
  watch: {
    /**
     * Watches 'loadingModuleSettings' to emit its value
     *
     * @param {boolean} nextVal - the new value
     */
    loadingModuleSettings(nextVal) {
      this.$emit('loadingChange', nextVal);
    },
  },
};
</script>

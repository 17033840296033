<template>
  <div v-if="isVisible" class="pui-attribute-group-panel pui-attribute-group-panel__root">
    <pui-group-panel
      v-if="group.showTitle !== false"
      :defaultClosed="!group.isOpenByDefault"
      :disabled="group.disabled"
      :title="piivoTranslate(group)"
      class="pui-attribute-group-panel__panel"
    >
      <slot></slot>
    </pui-group-panel>
    <div v-else class="pui-group-panel-hidden pui-attribute-group-panel__panel hidden">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

import services from '../../../../core/services';

export default {
  name: 'PuiAttributeGroupPanel',
  components: {},
  props: {
    group: {
      required: true,
    },
  },
  computed: {
    isVisible() {
      // If permission property is undefined or null, show attribute group
      return (
        this.group.permission == null ||
        services.getService('auth').hasPermission(this.group.permission)
      );
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
  },
};
</script>

import './components/table/editors';
import './components/table/renderers';
import './static/style/_all.scss';

import Vue from 'vue';

import helpers from '../../core/helpers';
import services from '../../core/services';
import store from '../../core/store';
import { components } from './components';
import detailsHelper from './helpers/detailsHelper';
import filtersHelper from './helpers/filtersHelper';
import tableHelper from './helpers/tableHelper';
import { SessionSettingsService } from './services/sessionSettingsService';
import { UploadManager } from './services/uploadManager';
import { UserSettingsService } from './services/userSettingsService';
import {
  NAMESPACE as SESSION_SETTINGS_NAMESPACE,
  SessionSettingsModule,
} from './store/sessionSettings';
import { NAMESPACE as USER_SETTINGS_NAMESPACE, UserSettingsModule } from './store/userSettings';

/**
 * Function to install common app module
 * */
export function install() {
  for (const component of components) {
    Vue.component(component.name, component);
  }
  if (store.state[USER_SETTINGS_NAMESPACE]) {
    store.registerModule(USER_SETTINGS_NAMESPACE, UserSettingsModule, {
      preserveState: true,
    });
  } else {
    store.registerModule(USER_SETTINGS_NAMESPACE, UserSettingsModule);
  }
  if (store.state[SESSION_SETTINGS_NAMESPACE]) {
    store.registerModule(SESSION_SETTINGS_NAMESPACE, SessionSettingsModule, {
      preserveState: true,
    });
  } else {
    store.registerModule(SESSION_SETTINGS_NAMESPACE, SessionSettingsModule);
  }

  /* Install services */
  services.addService('uploadManager', UploadManager);
  services.addService('userSettings', new UserSettingsService());
  services.addService('sessionSettings', new SessionSettingsService());

  /* Install helpers */
  helpers.addHelper('detailsHelper', detailsHelper);
  helpers.addHelper('filtersHelper', filtersHelper);
  helpers.addHelper('tableHelper', tableHelper);
}

install();
